import * as React from 'react';
import AlertsPopup from '../components/AlertsPopup';
interface Alert {
  id: string,
  text: string,
  alertEndTime: number,
}

export const ALERT_VISIBLE_TIME_MILLISECOND = 3000;

function removeOldAlerts(alerts: Alert[]) {
  let cleanedUpAlerts: Alert[] = [];
  for (const alert of alerts) {
    if (alert.alertEndTime > Date.now()) {
      cleanedUpAlerts.push(alert);
    }
  }
  return cleanedUpAlerts;
};

const AlertContext = React.createContext({
  alerts: [],
  setAlerts: () => {},
});

export function AlertProvider({ children }) {
  const [alerts, setAlerts] = React.useState<Alert[]>([]);

  const setAlert = (text) => {
    setAlerts([
      ...removeOldAlerts(alerts),
      {
        id: `${Date.now()}`,
        text,
        alertEndTime: Date.now() + ALERT_VISIBLE_TIME_MILLISECOND
      },
    ]);
  };

  return (
    <AlertContext.Provider value={{ alerts, setAlert }}>
      <AlertsPopup />
      {children}
    </AlertContext.Provider>
  );
};

export default AlertContext;