import * as React from 'react';
import useAlert from '../hooks/useEffects/useAlert';
import {
  root,
  alert,
} from './AlertsPopup.module.scss';
import { ALERT_VISIBLE_TIME_MILLISECOND } from '../contexts/AlertContext';

function Alert({ id, text }) {
  return (
    <div
      className={alert}
      style={{animationDuration: `${ALERT_VISIBLE_TIME_MILLISECOND / 1000}s`}}>
      {text}
    </div>
  )
};

export default function AlertsPopup() {
  const { alerts } = useAlert();
  const alertElements = [];

  for (const alert of alerts) {
    alertElements.push(<Alert key={alert.id} {...alert} />);
  }

  return (
    <div className={root}>
      {alertElements}
    </div>
  );
};
