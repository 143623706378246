exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-how-to-open-powershell-tsx": () => import("./../../../src/pages/projects/how-to-open-powershell.tsx" /* webpackChunkName: "component---src-pages-projects-how-to-open-powershell-tsx" */),
  "component---src-pages-projects-jump-scare-tsx": () => import("./../../../src/pages/projects/jump-scare.tsx" /* webpackChunkName: "component---src-pages-projects-jump-scare-tsx" */),
  "component---src-pages-projects-pop-up-windows-tsx": () => import("./../../../src/pages/projects/pop-up-windows.tsx" /* webpackChunkName: "component---src-pages-projects-pop-up-windows-tsx" */),
  "component---src-pages-projects-the-inaccessible-folder-tsx": () => import("./../../../src/pages/projects/the-inaccessible-folder.tsx" /* webpackChunkName: "component---src-pages-projects-the-inaccessible-folder-tsx" */),
  "component---src-pages-projects-the-undeletable-path-tsx": () => import("./../../../src/pages/projects/the-undeletable-path.tsx" /* webpackChunkName: "component---src-pages-projects-the-undeletable-path-tsx" */),
  "component---src-pages-projects-two-folders-with-the-same-name-in-the-desktop-tsx": () => import("./../../../src/pages/projects/two-folders-with-the-same-name-in-the-desktop.tsx" /* webpackChunkName: "component---src-pages-projects-two-folders-with-the-same-name-in-the-desktop-tsx" */)
}

