module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-P7PCZH2GNV"],"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Soft Laugh","short_name":"Soft Laugh","description":"Software that aims for laughter.","lang":"en","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"standalone","theme_color_in_head":false,"icon":"./src/images/icon2.svg","legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"23b0579b73be98a237f6f33c2bb142fb"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
